import * as React from "react";
import * as ReactDOM from "react-dom";
import ReCAPTCHA from "react-google-recaptcha";

import { onLoad } from "spry-react/utils/onLoad";
import { LabeledTextField } from "spry-react/Text/textField";
import { LabeledCheckboxField } from "spry-react/Checkbox/labeledCheckboxField";
import { CheckboxField } from "spry-react/Checkbox/checkboxField";
import { ErrorDisplayList } from "spry-react/Errors/errorDisplayList";
import { ErrorList } from "spry-react/Errors/errorList";

import { IPortalAppContext } from "../Shared/appContext";
import { PortalApp } from "../Shared/portalApp";
import { SignInPageResponse } from "Components/Api/GeneratedDTOs/Controllers/SignInPageResponse";
import { PortalFullPage } from "../Shared/portalPage";
import { AccountsApiClient } from "./accountsApiClient";

import "./signin.less";
import { Link } from "spry-react/Link/link";

interface Props {
  context: IPortalAppContext;
  accountsApiClient: AccountsApiClient;
  recaptchaPublicSiteKey: string;
  createNewUserPageUrl: string;
  forgotPasswordPageUrl: string;
  forgotUsernamePageUrl: string;
  returnUrl: string;
  licensingOrganizationPhone: string;
  messageAboveLogin: string | null;
  messageBelowLogin: string | null;
}

interface State {
  username: string;
  password: string;
  showPassword: boolean;
  staySignedIn: boolean;
  recaptchaResponse?: string;
  requiresCaptcha: boolean;
  errors: ErrorList;
}

class SignInPage extends React.Component<Props, State> {
  private recaptcha: ReCAPTCHA | null;

  constructor(props: Props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      showPassword: false,
      staySignedIn: false,
      requiresCaptcha: false,
      errors: new ErrorList([]),
    };
  }

  render() {
    const props = this.props;
    const state = this.state;

    const headerContent = <h1>Login</h1>;

    const forgotPasswordPageUrl = this.props.forgotPasswordPageUrl + (state.username.length > 0 ? `?username=${state.username}` : "");

    const messageAboveLoginHtml = {
      __html: props.messageAboveLogin || "",
    };

    const messageBelowLoginHtml = {
      __html: props.messageBelowLogin || "",
    };

    return (
      <PortalFullPage title="MatrixLicensor Portal Sign In" headerContent={headerContent} borderedHeader={true} context={props.context}>
        <div className="signin-full">
          <div style={{ paddingTop: "5em" }}></div>
          {props.messageAboveLogin && (
            <div className="signin-wide-content">
              <div className="signin-custom-message">
                <p dangerouslySetInnerHTML={messageAboveLoginHtml}></p>
              </div>
            </div>
          )}
          <div className="signin-content">
            <form className="signin-panel" onSubmit={this.onSignInClicked} data-allow-enter>
              <ErrorDisplayList errors={state.errors.getList()} />
              <div className="field">
                <LabeledTextField label="Username" value={state.username} onChange={this.onUsernameChanged} autoFocus />
              </div>
              <div className="field">
                <LabeledTextField
                  label="Password"
                  type={state.showPassword ? "text" : "password"}
                  value={state.password}
                  onChange={this.onPasswordChanged}
                />
              </div>
              <div className="field" style={{ textAlign: "right" }}>
                <label className="label l-box-hr-1-2x" htmlFor="show-password" style={{ display: "inline" }}>
                  Show Password
                </label>
                <CheckboxField id="show-password" value={state.showPassword} onToggle={this.onShowPasswordChanged} />
              </div>
              {state.requiresCaptcha && (
                <ReCAPTCHA ref={(r) => (this.recaptcha = r)} sitekey={props.recaptchaPublicSiteKey} onChange={this.onRecaptchaChanged} />
              )}
              <div className="signin-buttons">
                <button type="submit" className="signin-login-button pure-button pure-button-primary">
                  Log In
                </button>
              </div>
              <div className="signin-options">
                <div className="field" style={{ width: "50%" }}>
                  <LabeledCheckboxField label="Stay signed in" value={state.staySignedIn} onToggle={this.onStaySignedInToggled} />
                </div>
                <div style={{ width: "50%", textAlign: "right" }}>
                  <div>
                    <a href={forgotPasswordPageUrl}>Forgot your password?</a>
                  </div>
                  <div>
                    <a href={props.forgotUsernamePageUrl}>Forgot your username?</a>
                  </div>
                </div>
              </div>
            </form>

            <div className="signin-newuser-panel">
              <div className="field">
                <label>
                  New user? <Link onClick={this.onCreateNewUserClicked}>Register here.</Link>
                </label>
              </div>
            </div>
          </div>
          {props.messageBelowLogin && (
            <div className="signin-wide-content">
              <div className="signin-custom-message">
                <p dangerouslySetInnerHTML={messageBelowLoginHtml}></p>
              </div>
            </div>
          )}
        </div>
      </PortalFullPage>
    );
  }

  private onUsernameChanged = (username: string) => this.setState({ username });

  private onPasswordChanged = (password: string) => this.setState({ password });

  private onShowPasswordChanged = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  private onStaySignedInToggled = () => {
    this.setState((state) => {
      return { staySignedIn: !state.staySignedIn };
    });
  };

  private onRecaptchaChanged = (value: string) => {
    this.setState({ recaptchaResponse: value });
  };

  private onSignInClicked = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    var request = {
      username: this.state.username,
      password: this.state.password,
      staySignedIn: this.state.staySignedIn,
      recaptchaResponse: this.state.recaptchaResponse!,
      returnUrl: this.props.returnUrl,
    };
    this.props.accountsApiClient
      .signIn(request)
      .catch()
      .then((response) => {
        if (response.success) this.props.context.setWindowLocationHref(response.redirectUrl);
        else {
          if (this.recaptcha) {
            this.recaptcha.reset();
          }
          this.setState({
            requiresCaptcha: response.requiresCaptcha,
            recaptchaResponse: undefined,
            errors: new ErrorList(response.errors || []),
            password: "",
          });
        }
      });
  };

  private onCreateNewUserClicked = () => {
    this.props.context.setWindowLocationHref(this.props.createNewUserPageUrl);
  };
}

onLoad((data: SignInPageResponse) => {
  ReactDOM.render(
    <PortalApp
      data={data}
      renderChildren={(context) => {
        const accountsApiClient = new AccountsApiClient(data.accountsApiClientUrls, context.fetcher, context.commandFetcher);
        return (
          <SignInPage
            context={context}
            accountsApiClient={accountsApiClient}
            recaptchaPublicSiteKey={data.recaptchaPublicSiteKey}
            createNewUserPageUrl={data.createNewUserPageUrl}
            forgotPasswordPageUrl={data.forgotPasswordPageUrl}
            forgotUsernamePageUrl={data.forgotUsernamePageUrl}
            returnUrl={data.returnUrl}
            licensingOrganizationPhone={data.licensingOrganizationPhone}
            messageAboveLogin={data.messageAboveLogin}
            messageBelowLogin={data.messageBelowLogin}
          />
        );
      }}
    />,
    document.getElementById("root"),
  );
});
